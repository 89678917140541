import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { getVacancyList } from "../actions/common"
import { isEmpty } from "../config/funcs"
import { Oval } from "react-loader-spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { employeeValidation } from "../component/Validation"
import { faUser, faLocationDot, faSuitcase, faMoneyBill1Wave, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Modal } from "react-bootstrap"
import { applyOffer } from "../actions/common"
import SuccessLottie from "../component/successLottie"

const initialValue = {
    fname: "",
    lname: "",
    gender: "",
    email: "",
    contact: "",
    nationality: "",
    experience: "",
    position: "",
    salary: "",
    address: "",
    cover: ""
}


const Vacancy = () => {

    const [data, setData] = useState([])
    const [formValue, setFormValue] = useState(initialValue)
    const [singleData, setSingleData] = useState([])
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [validation, setValidation] = useState({})
    const [countdown, setCountdown] = useState(5)
    const [optStatus, setOptStatus] = useState("")
    const [optText, setOptText] = useState("")


    const { fname, lname, gender, email, contact, nationality, experience, position, salary, address, cover } = formValue

    const getVacancyData = async () => {
        try {
            let { status, result, message, error } = await getVacancyList()

            if(status == "success") {
                setData(result)
            } else {
                console.log("Failed to load due to: ", error)
            }
        } catch (err) {
            alert("Alert fetching vacancies, try refreshing the website. Sorry for the inconvenience.")
        }
    }

    const decodeHtmlEntities = (input) => {
        const doc = new DOMParser().parseFromString(input, 'text/html')
        return doc.documentElement.textContent
    }

    const openVacancy = (item) => { 
        setSingleData(item)
        window.scrollTo(0, 0)
    }

    const applyPost = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setOptStatus("")
    }

    const handleBack = () => {
        setSingleData([])
        window.scrollTo(0, 0)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValue({
            ...formValue, [name]: value
        })
    }

    const handleSelectChange = (e, name) => {
        const { value } = e.target
        setFormValue({
            ...formValue, [name]: value
        })
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0]
        if (selectedFile) {
            const maxSize = 5 * 1024 * 1024
            if (selectedFile.size > maxSize) {
                alert("File size exceeds 5 MB. Please choose a smaller file.")
                e.target.value = null
                setSelectedFile(null)
            } else {
                setSelectedFile(selectedFile)
            }
        }
    }

    const handleSubmit = async(e) => {
        try {
            let formData = formValue
            formData.resume = isEmpty(selectedFile) ? null : selectedFile
            let _error = employeeValidation(formData)
            setValidation(_error)
            if(Object.keys(_error).length > 0 && !isEmpty(_error)) return

            setLoader(true)
            let { status, message, error } = await applyOffer(formData)
            if(status == "success") setOptText(message)
            else setOptText(error)
            setTimeout(() => {
                setCountdown(5)
                setOptStatus(status)
                setLoader(false)
            }, 2000);
        } catch (err) {
            alert("Operation failure: Please contact administrator if the issue persists. Error: " + err.message)
        }
    }

    useEffect(() => {
        setFormValue(initialValue)
        setValidation({})
        setSelectedFile(null)
    }, [show])

    useEffect(() => {
        getVacancyData()
    }, [])

    useEffect(() => {
        let timer
        if (countdown > 0) {
          timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1)
          }, 1000)
        } else if (countdown === 0) {
          clearInterval(timer)
          handleClose()
        }
    
        return () => clearInterval(timer)
      }, [countdown])

    return (
        <>
            <section className="vacancySection p-0" style={{backgroundColor: "#9CC3D5FF" }}>
                    {
                        singleData && !isEmpty(singleData) && singleData.length != 0 ?
                            <div className="container col-lg-12 mt-3 pt-5">
                                <div className="container mb-4 pt-5">
                                    <div className="card" style={{ borderRadius: "20px" }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h4 className="text-center pb-2" style={{ color: "#0f2b52" }}>
                                                        <b>
                                                            { singleData.position }
                                                        </b>
                                                    </h4>
                                                    <div className="text-center" style={{ color: "#0f2b52", fontSize: "15px" }}>
                                                        <FontAwesomeIcon className="pe-1" fontSize={18} icon={faLocationDot} /> Dubai, UAE
                                                    </div>
                                                    <br />
                                                    <div className=" col-sm-12 text-center" style={{ color: "#0f2b52", fontSize: "15px" }}>
                                                        <div className="given col-sm-4 px-4">
                                                            <FontAwesomeIcon className="pe-1" fontSize={16} icon={faUser} /> Availability: {singleData.availability}
                                                        </div>
                                                        <div className="given col-sm-4 px-4">
                                                            <FontAwesomeIcon className="pe-1" fontSize={16} icon={faSuitcase} /> Experience: {singleData.experience}
                                                        </div>
                                                        <div className="given col-sm-4 px-4">
                                                            <FontAwesomeIcon className="pe-1" fontSize={16} icon={faMoneyBill1Wave} /> Salary: {singleData.salary}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-lg-12" style={{ color: "#0f2b52" }} dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(singleData.description) }} />
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-lg-12 d-flex justify-content-between">
                                                    <button className="btn view btn-block col-md-1 btn-primary btn-sm" type="button" onClick={handleBack}>
                                                        Go Back
                                                    </button>
                                                    {
                                                        singleData.status == "1" ?
                                                            <button className="btn view btn-block col-md-4 btn-primary btn-sm" type="button" onClick={() => {applyPost();setOptText("");}}>
                                                                Apply Now
                                                            </button>
                                                        :
                                                            <button className="btn view btn-block col-md-4 btn-danger btn-sm" type="button" disabled="" id="">
                                                                Application Closed
                                                            </button>  
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className="container col-lg-8 mt-3 pt-5">
                                <div className="section-title">
                                    <h2 className="pt-5 mb-0">Vacancy List</h2>
                                </div>
                                {
                                    data && !isEmpty(data) && data.length != 0 && data?.map((item) => {
                                        return (
                                            <div className="card vacancy-list" style={{ backgroundColor: "#fff", borderRadius: "20px" }} onClick={() => { openVacancy(item) }}>
                                                <div className="card-body">
                                                    <h4>
                                                        <b className="filter-txt" style={{ color: "#0f2b52" }}>
                                                            {item.position}
                                                        </b>
                                                    </h4>
                                                    <div className="location" style={{ color: "#0f2b52", fontSize: "15px" }}>
                                                        <FontAwesomeIcon className="pe-1" fontSize={18} icon={faLocationDot} /> Dubai, UAE
                                                    </div>
                                                    <br />
                                                    <div className="col-sm-12 text-center" style={{ color: "#0f2b52", fontSize: "15px" }}>
                                                        <div className="given col-sm-4 px-3">
                                                            <FontAwesomeIcon className="pe-1" fontSize={16} icon={faUser} /> Availability: {item.availability}
                                                        </div>
                                                        <div className="given col-sm-4 px-3">
                                                            <FontAwesomeIcon className="pe-1" fontSize={16} icon={faSuitcase} /> Experience: {item.experience}
                                                        </div>
                                                        <div className="given col-sm-4 px-3">
                                                            <FontAwesomeIcon className="pe-1" fontSize={16} icon={faMoneyBill1Wave} /> Salary: {item.salary}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="pt-5 pt-lg-2">
                                                        <h5 style={{ color: "#0f2b52" }}>Description</h5>
                                                        <small className="truncate filter-txt">
                                                            {
                                                                decodeHtmlEntities(item.description).replace(/<[^>]*>/g, '')
                                                            }
                                                        </small>
                                                    </div>
                                                    <hr />
                                                    <div className="col-lg-12">
                                                        <div className="text-end"> 
                                                            <button className="btn view col-md-2" style={{ fontSize: "12px", padding: "10px 0", borderRadius: "50px" }}>
                                                                View Detail <FontAwesomeIcon className="ps-1" fontSize={16} icon={faArrowRight} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
            </section>

            <Modal
                show={show}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
            >
                {
                    loader &&
                    <Oval
                        height={200}
                        width={200}
                        color="#0d254d"
                        wrapperStyle={{}}
                        wrapperClass="customClass"
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#9CC3D5FF"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                }
                <Modal.Body>
                    {
                        optStatus == "" ?
                            <div className="container-fluid">
                                <div className="row">
                                    <h3 className="mb-0">Application form for <b>{singleData.position}</b> </h3>
                                </div>
                                <hr />
                                <form className="p-0 pt-2">
                                    <input type="hidden" name="id" value="" />
                                    <input type="hidden" name="position_id" value="" />
                                    <div className="col-md-12">
                                        <div className="row form-group pb-4">
                                            <div className="col-md-4">
                                                <label className="control-label">First Name</label>
                                                <input type="text" className={`form-control ${validation.fname == "REQUIRED" && "border-danger"}`} name="fname" onChange={handleChange} />
                                                {
                                                    validation.fname == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label className="control-label">Last Name</label>
                                                <input type="text" className={`form-control ${validation.lname == "REQUIRED" && "border-danger"}`} name="lname" onChange={handleChange} />
                                                {
                                                    validation.lname == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label className="control-label">Gender</label>
                                                <select 
                                                    name="gender"
                                                    className={`form-control ${validation.gender == "REQUIRED" && "border-danger"}`}
                                                    onChange={(e) => {handleSelectChange(e, "gender")}}
                                                >
                                                    {
                                                        gender == "" && <option value="">None</option>
                                                    }
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                {
                                                    validation.gender == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row form-group pb-4">
                                            <div className="col-md-4">
                                                <label className="control-label">Email</label>
                                                <input type="email" className={`form-control ${validation.email == "REQUIRED" && "border-danger"}`} name="email" onChange={handleChange} />
                                                {
                                                    validation.email == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label className="control-label">Contact</label>
                                                <input type="text" className={`form-control ${validation.contact == "REQUIRED" && "border-danger"}`} name="contact" onChange={handleChange} />
                                                {
                                                    validation.contact == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label className="control-label">Nationality</label>
                                                <input type="text" className={`form-control ${validation.nationality == "REQUIRED" && "border-danger"}`} name="nationality" onChange={handleChange} />
                                                {
                                                    validation.nationality == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row form-group pb-4">
                                            <div className="col-md-4">
                                                <label className="control-label">Experience</label>
                                                <input type="text" className={`form-control ${validation.experience == "REQUIRED" && "border-danger"}`} name="experience" onChange={handleChange} />
                                                {
                                                    validation.experience == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label className="control-label">Current Position</label>
                                                <input type="text" className={`form-control ${validation.position == "REQUIRED" && "border-danger"}`} name="position" onChange={handleChange} />
                                                {
                                                    validation.position == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <label className="control-label">Salary Expectation</label>
                                                <input type="text" className={`form-control ${validation.salary == "REQUIRED" && "border-danger"}`} name="salary" onChange={handleChange} />
                                                {
                                                    validation.salary == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row form-group pb-4">
                                            <div className="col-md-6">
                                                <label className="control-label">Address</label>
                                                <textarea cols="30" rows="3" className={`form-control ${validation.address == "REQUIRED" && "border-danger"}`} name="address" onChange={handleChange} ></textarea>
                                                {
                                                    validation.address == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <label className="control-label">Cover Letter</label>
                                                <textarea cols="30" rows="3" placeholder="(Optional)" className={`form-control ${validation.cover == "REQUIRED" && "border-danger"}`} name="cover" onChange={handleChange}></textarea>
                                                {
                                                    validation.cover == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row form-group pb-3">
                                            <div className="col-md-4">
                                                <label className="control-label">Upload Resume <i>(PDF file, max 5MB)</i></label>
                                                <input type="file" className={`form-control ${validation.resume == "REQUIRED" && "border-danger"}`} onChange={handleFileChange} accept="application/pdf" name="resume" required />
                                                {
                                                    validation.resume == "REQUIRED" &&
                                                    <span className="errorText">* This field is required.</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn col-lg-2 view btn-primary" onClick={handleSubmit}>Submit</button>
                                    <button type="button" className="btn col-lg-2 view1 btn-outline-dark" onClick={handleClose}>Cancel</button>
                                </div>
                            </div>
                        : optStatus == "success" ?
                        <>
                            <h5 className="pb-4 m-0 text-center">Your application has been successfully submitted. Our agent will be in touch shortly</h5>
                            <SuccessLottie />
                            <h7 className="d-block text-center w-100 pt-4"><i>Returning to page in {countdown}</i></h7>
                        </>
                        :
                        <>
                            <h4 className="pb-4 m-0">Submission failed. Something must have gone wrong, please contact careers@fintrekmarketing.com for application submission.</h4>
                            <h7 className="d-block text-center w-100 pt-4"><i>Returning to page in {countdown}</i></h7>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Vacancy