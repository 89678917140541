import React, { useEffect } from "react"
import headerdebt from "../assets/imgs/Debtcollection/headerdebt.png"
import datacollection from "../assets/imgs/Debtcollection/datacollection.png"
import debt from "../assets/imgs/Debtcollection/debt.png"
import workschedule from "../assets/imgs/Debtcollection/workschedule.png"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"


const RiskAdvisory = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">
                <section class="breadcrumbs-inner" style={{background:`linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)),url(${headerdebt})`}}>
                    <div class="container">
                        <h2 style={{textAlign: "center;"}} data-aos="fade-right"><center>Risk Advisory Services</center></h2>
                    </div>
                </section>
                <section id="service-about us" class="service-about">
                    <div class="container">
                        <div class="section-title" data-aos="fade-up">
                            <p>
                                Risk management is pivotal in today's complex and rapidly changing business environment. Our dedicated team provides top-tier Risk Advisory Services 
                                to navigate these challenges effectively. We specialize in identifying, assessing, and managing operational, cyber, and technological risks to safeguard your interests.
                            </p>
                        </div>
                        <div class="row content" data-aos="fade-right">
                            <div class="col-lg-6">
                                <ul>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> 
                                        Ready your business for future challenges.
                                    </li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> 
                                        Strategies that match your risk profile.
                                    </li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> 
                                        Access best practices from global experts.
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                                <p>
                                    Fintrek Marketing bolsters your business's resilience, equipping you to manage and anticipate risks, ensuring a robust, adaptable environment for future growth.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="cta" class="cta">
                    <div class="container" data-aos="fade-right">
                        <div class="text-center">
                            <h3>CONSULT OUR EXPERTS</h3>
                            <p>When it comes to managing risks, our experts are ready to assist you in every step to ensure robust business operations. Choose our Risk Advisory Services today and build a stronger, more secure future.</p>
                            <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }}  className="cta-btn">
                                Book an Appointment
                            </Link>
                        </div>
                    </div>
                </section>
                <section id="service-about" class="service-about" data-aos="fade-up">
                    <div class="container">
                        <div class="section-title">
                            <h2>Services We Provide</h2>
                            <p>We ensure that our clients' needs are always met by providing comprehensive, proactive, and strategic risk management.</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch ">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={debt} /></i></div>
                                        <h4>Risk Assessment</h4>
                                        <p>We evaluate and identify potential risks to your business operations and assets. <br /> <br /></p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={datacollection}/></i></div>
                                        <h4>Risk Mitigation</h4>
                                        <p>We develop and implement strategies to minimize exposure to financial, operational, and reputational risks. <br/></p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch ">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={workschedule}/></i></div>
                                        <h4>Risk Optimization</h4>
                                        <p>We enhance your risk management capabilities to turn potential risks into opportunities for growth. </p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default RiskAdvisory