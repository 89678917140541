import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './layout/Header'
import Home from './pages/Home'
import OurServices from './pages/OurServices'
import About from './pages/About'
import Founder from './pages/Founder'
import Team from './pages/Team'
import NewsUpdate from './pages/NewsUpdate'
import ConsumerServices from './pages/ConsumerServices'
import CorporateFinancing from './pages/CorporateFinancing'
import MarketingServices from './pages/MarketingServices'
import DebtCR from './pages/DebtCollectionRecovery'
import Careers from './pages/Careers'
import Footer from './layout/Footer'
import Contact from './pages/Contact'
import Awards from './pages/Awards'
import Consulation from './pages/Consulation'
import Vacancy from './pages/Vacancy'
import RiskAdvisory from './pages/RiskAdvisory'

const App = () => {
  return (
    <Router>
      <Header />

      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/our-services' element={<OurServices />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/founder' element={<Founder />}/>
        <Route path='/team' element={<Team />}/>
        <Route path='/news-updates' element={<NewsUpdate />}/>
        <Route path='/awards' element={<Awards />}/>
        <Route path='/consumer-services' element={<ConsumerServices />}/>
        <Route path='/corporate-financing' element={<CorporateFinancing />}/>
        <Route path='/marketing-services' element={<MarketingServices />}/>
        <Route path='/debt-collection-recovery' element={<DebtCR />}/>
        <Route path='/risk-advisory' element={<RiskAdvisory />}/>
        <Route path='/careers' element={<Careers />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='/consulation' element={<Consulation />}/>
        <Route path='/vacancies' element={<Vacancy />}/>
      </Routes>

      <Footer />
    </Router>
  )
}

export default App