import React, { useEffect } from "react"
import corporatefin from "../assets/imgs/corporatefinance/corporatefin.png"
import Fleet from "../assets/imgs/corporatefinance/Fleet.png"
import SME from "../assets/imgs/corporatefinance/SME.png"
import ExchangeRate from  "../assets/imgs/corporatefinance/ExchangeRate.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"


const CorporateFinancing = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">
                <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)), url(${corporatefin}) center`}}>
                    <div class="container">
                        <h1 className="text-center" data-aos="fade-right">
                            Corporate financing
                        </h1>
                    </div>
                </section>
                <section id="service-about us" class="service-about">
                    <div class="container">
                        <div class="section-title" data-aos="fade-up">
                            <p>Our financial advisors have over 20 years experience in the financial field. We assist and facilitate financial products to companies for the growth of their business.</p>
                        </div>
                        <div class="row content" data-aos="fade-up">
                            <div class="col-lg-8 mx-auto">
                                <p>
                                    Our company offers a range of services for small and medium companies who want to improve their financial performance. If the need is for financial, we assist you.
                                </p>
                                <ul>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> We are committed to helping grow your business providing with right financing. </li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> We help you make the right selection & find the most profitable solution for your business.</li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> We will make sure that your operational costs are reduced in the best possible way.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="cta" class="cta">
                    <div class="container" data-aos="fade-right">

                        <div class="text-center">
                            <h3>CONSULT OUR EXPERTS</h3>
                            <p>When time is of the essence, you can find all the services you need in one place. All you need to do is pick what you want, fill in some details and submit your application.</p>
                            <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }}  className="cta-btn">
                                Book an Appointment
                            </Link>
                        </div>

                    </div>
                </section>


                <section id="service-about" class="service-about" data-aos="fade-up">
                    <div class="container">
                        <div class="section-title">
                            <h2>Services We Provide</h2>
                            <p>Fintrek persistently aware financial needs are vital and accordingly, we provide a wide spectrum of services with ongoing adaptive management. We review the current financial situation of our customer meticulously and aid them navigate towards to achieve the financial goals.</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={Fleet} alt="" /></i></div>
                                        <h4>Fleet Financing</h4>
                                        <p>We provide you with cost effective solutions for all aspects of your fleet management.</p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={SME} alt="" /></i></div>
                                        <h4>SME Loans</h4>
                                        <p>We offer SME consultation and help you out in the process of business setup also.</p>
                                    </div>
                                </Link>
                            </div>
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="text-center"></div>
                                        <div class="icon"><i><img src={ExchangeRate} alt="" /></i></div>
                                        <h4>Trade Financing</h4>
                                        <p>We are arranging innovative trade and export finance solutions that meet your needs.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default CorporateFinancing