import React, { useEffect } from "react"
import header from "../assets/imgs/about/header.jpg"
import nbdLogo from "../assets/imgs/logos/enbd.svg"
import mashreqLogo from "../assets/imgs/logos/mashreq.svg"
import gemsLogo from "../assets/imgs/logos/gems.png"
import sibLogo from "../assets/imgs/logos/sib.svg"
import cbdLogo from "../assets/imgs/logos/cbd.svg"
import dibLogo from "../assets/imgs/logos/dib.svg"
import eisLogo from "../assets/imgs/logos/eis.svg"
import Picture7 from "../assets/imgs/about/Picture7.jpg"
import leadership from "../assets/imgs/about/leadership.jpg"
import risk from "../assets/imgs/about/risk.jpg"
import clientfocus from "../assets/imgs/about/clientfocus.jpg"
import technology from "../assets/imgs/about/technology.jpg"
import institute from "../assets/imgs/about/institute.jpg"
import resultorientation from "../assets/imgs/about/resultorientation.jpg"
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"


const About = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])
    
    return (
        <>
            <main id="main">
                <section id="breadcrumbs" class="d-flex align-items-center" style={{background: `url(${header})`, backgroundSize: "cover"}}>
                    <div class="container position-relative" data-aos="fade-up" data-aos-delay="500">
                        <h1 class="pt-3">About Fintrek Marketing</h1>
                    </div>
                </section>
                <section id="about" class="about" style={{paddingTop: "0"}}>
                    <div class="container p-3 p-lg-5 pt-2">
                        <div class="section-title" data-aos="fade-up">
                            <h2 class="pt-3">Fintrek Marketing Story</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6" data-aos="fade-up">
                                <img src={Picture7} class="img-fluid" alt="fintrek-marketing" style={{borderRadius: "20px"}} />
                            </div>
                            <div class="col-lg-6 p-2 pt-3 pt-lg-4 content" data-aos="fade-up">
                                <p>
                                    Fintrek Marketing is one of the fastest growing direct sales agency in UAE,
                                    founded by
                                    <Link to={"/founder"}>
                                    <span><b> Aamir Yousuf</b></span>
                                    </Link>
                                    . Fintrek Marketing offers a wide range of service to banks and financial
                                    institutes in UAE. We are specialized in personal loan, financing and credit
                                    card sales. Our company is based on the principles of customer satisfaction,
                                    teamwork and excellent service that support equally to our client to achieve
                                    their goals.
                                </p>
                                <p>
                                    Fintrek marketing has tied up with multiple Commercial and Islamic Banks.
                                    Our footprint connects emerging and high growth markets with more established
                                    financial economies, granting us a channel partner.
                                </p>
                            </div>
                            <div class="about content my-5" data-aos="fade-up">
                                <div class="clients-inner">
                                    <h2 className="text-center m-0">Our Official Channel Partners</h2>
                                    <div className="row homePartner">
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={eisLogo} height={"100px"} alt="" />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={nbdLogo} height={"100px"} alt="" />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={mashreqLogo} height={"100px"} alt="" />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={gemsLogo} height={"100px"} alt="" />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={sibLogo} height={"100px"} alt="" />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={cbdLogo} height={"100px"} alt="" />
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-5">
                                            <img src={dibLogo} height={"100px"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container">
                                <div class="content" data-aos="fade-up">
                                    <p>We provide one-stop solution for your financial needs. We act as a bridge
                                        between bank and customer in getting finance for you. If you are looking
                                        for personal loan then we can facilitate loans through best bank with
                                        nominal interest rate in UAE. You do not need to leave your office or
                                        home to process the loan we will visit you and complete the processing.
                                    </p>
                                    <p>
                                        Apply a credit card free for life with easy application process that
                                        makes every day spends more rewarding, swipe anywhere & pay with a tap.
                                        We know that you want to have a credit card that's as easy-to-use and that
                                        we will assist you.
                                    </p>
                                    <p>You can buy your dream home or fulfil your child education or urgent
                                        requirement of cash through Fintrek Marketing with easy application
                                        process.
                                    </p>
                                    <p>From financial consultancy to marketing, sales to service our
                                        industrial experience is something we feel pleased about and the
                                        service we provide, will show what we believe and value. We are a
                                        team of bankers with no boundaries, just legitimacy and liability.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 mx-auto">
                                <div class="section-title">
                                    <h2>Welcome Words by Our Founder</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-11 mx-auto">
                                <blockquote class="blockquote blockquote-custom p-3 p-lg-5 shadow rounded">
                                    <div class="blockquote-custom-icon shadow-sm" style={{background: "#0f2b52"}}>                                    <FontAwesomeIcon className="position-relative" color="white" fontSize={30} icon={faQuoteLeft} /></div>
                                    <p class="mb-0 mt-2 font-italic">"Welcome to Fintrek Marketing. We have started Fintrek Marketing in 2014 with one office and 8 staff. In 7 years now we operate from 7 different locations in UAE with more than 200 staff. We are the company that has reformed as an institute where young and energetic staff trained on quality and compliance. We refine them to be mature banker within short span of time. </p>
                                    <p>Our Staff has solid work ethics and desire to succeed. Positive culture, enthusiasm and learning environment energise everyone to create impact. It can be impact on customers because of excellent service. Impact on employees, by helping to build their career and by seeing the satisfaction they get from doing their job well. </p>
                                    <p>We are happy to serve you by providing best solution to your financial needs. Appreciate if you give us a chance to us to demonstrate our expertise." </p>
                                    <footer class="blockquote-Footer pt-4 mt-4">
                                        <cite title="Source Title" style={{textAlign: "center", color: "#0f2b52;"}}>Our slogan is "Mastering services"</cite>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="core-competence">
                    <div class="container" data-aos="fade-up">
                        <div class="section-title">
                            <h2>Core Competencies</h2>
                            <p>Our company core competencies are the combination of pooled knowledge and technical capacities that allow business to manage
                                complex requirements of our clients. We establish a footprint while gaining solid reputation and brand image.</p>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="card-wrapper col-lg-4 col-md-6 col-xs-12">
                                    <div class="card">
                                        <div class="card-img-wrapper">
                                            <img class="card-img-top" src={leadership} alt="Card image cap" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Leadership</h5>
                                            <div class="card-content">
                                                <p class="card-text">Leadership lead by example, build relationships, motivate and inspire people. Accountable for development of staff and share knowledge to support them.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-wrapper col-lg-4 col-md-6 col-xs-12">
                                    <div class="card">
                                        <div class="card-img-wrapper">
                                            <img class="card-img-top" src={technology} alt="Card image cap" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Technology</h5>
                                            <p class="card-text">We build strong technology infrastructure and keep updated.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="card-wrapper col-lg-4 col-md-6 col-xs-12">
                                    <div class="card">
                                        <div class="card-img-wrapper">
                                            <img class="card-img-top" src={clientfocus} alt="Card image cap" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Client Focus</h5>
                                            <p class="card-text">Keep customers at heart of activities in order to deliver a positive experience through understanding their needs and strengthening relationship.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-wrapper col-lg-4 col-md-6 col-xs-12">
                                    <div class="card">
                                        <div class="card-img-wrapper">
                                            <img class="card-img-top" src={risk} alt="Card image cap" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Risk Management</h5>
                                            <p class="card-text">
                                                Proactive identification and monitoring of risk. Mitigate through understanding of risk, escalate and adherence
                                                to policies, procedure and practices.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-wrapper col-lg-4 col-md-6 col-xs-12">
                                    <div class="card">
                                        <div class="card-img-wrapper">
                                            <img class="card-img-top" src={resultorientation} alt="Card image cap" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Result Orientation</h5>
                                            <p class="card-text">
                                                The ability to focus on the desired result of company or associate's work, setting challenging goals, focusing effort on the goals, and exceeding them.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-wrapper col-lg-4 col-md-6 col-xs-12">
                                    <div class="card">
                                        <div class="card-img-wrapper">
                                            <img class="card-img-top" src={institute} alt="Card image cap" />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title">Institute</h5>
                                            <p class="card-text">
                                                Fintrek as well a maneuver of institute, offers unabridged training for the young sales person and devises an elite talent to the banking sector to meet the industrial demands.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default About