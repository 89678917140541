import React, { useEffect } from "react"
import HeaderConsumer from "../assets/imgs/Consumerservices/HeaderConsumer.png"
import insurance from "../assets/imgs/Iconconsumerservices/insurance.png"
import Loan from "../assets/imgs/Iconconsumerservices/Loan.png"
import credit from "../assets/imgs/Iconconsumerservices/credit.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"


const ConsumerServices = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main id="main">


                <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)), url(${HeaderConsumer}) center`}}>
                    <div class="container">
                        <h1 style={{textAlign:" center;" }}data-aos="fade-right"><center>Consumer Services</center></h1>
                    </div>
                </section>


                <section id="service-about us" class="service-about">
                    <div class="container">

                        <div class="section-title" data-aos="fade-up">
                            <p>Our company is in this business for many years so we know what are aware of your requirements. We are specialized in providing services like auto loans, mortgage loans, credit cards and insurances etc. </p>
                        </div>

                        <div class="row content">
                            <div class="col-lg-6" data-aos="fade-right">
                                <p>
                                    We provide the latest technologies and services for our customers in order to make their lives easier. The strategies we follow,
                                </p>
                                <ul>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> Analyse and clear understanding of our clients needs.</li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> Guide and advice them about the overall process. </li>
                                    <li className="ps-0"><FontAwesomeIcon className="pe-2" fontSize={12} style={{paddingBottom: "1px"}} icon={faChevronRight} /> Support them to make a wise decision.</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 pt-3" data-aos="fade-left">
                                <p class="d-none d-md-block">
                                    Fintrek Marketing has maintain turnaround time for approval of upto 72 hours. If you are looking for loans, insurance or credit cards
                                    we can facilitate the process through best bank with nominal interest rate in UAE. We aware of your time and work, so you do not need to leave your office or home
                                    to process the loan we will visit you and complete the process.
                                </p>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="cta" class="cta">
                    <div class="container" data-aos="fade-right">
                        <div class="text-center">
                            <h3>CONSULT OUR EXPERTS</h3>
                            <p>When time is of the essence, you can find all the services you need in one place. All you need to do is pick what you want, fill in some details and submit your application.</p>
                            
                            <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }}  className="cta-btn">
                                Book an Appointment
                            </Link>
                        </div>
                    </div>
                </section>
                <section id="service-about" class="service-about" data-aos="fade-up">
                    <div class="container">

                        <div class="section-title">
                            <h2>Services We Provide</h2>
                            <p>We are the best approach to financial consultation. To the fullest extent we serve tirelessly and provide WOW service to the customer with zero complaints. We’re a consultancy committed to our customers’ success and makes them hassle-free. </p>
                            <p class="d-none d-md-block">We strive to be besides the customer on every time, clarify their path, alerting them from risk and offering them a peace of mind. </p>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={Loan}alt="" /></i></div>
                                        <h4>Loans</h4>
                                        <p> We can help you with getting mortgage loans, auto loans, and more.</p>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={credit} alt="" /></i></div>
                                        <h4>Credit Cards</h4>
                                        <p>Get a credit card free for life, without any annual charges with us.</p>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                    <div class="icon-box">
                                        <div class="icon"><i><img src={insurance} alt="" /></i></div>
                                        <h4>Insurance</h4>
                                        <p>We help to find an affordable insurance plan that covers what you need.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}

export default ConsumerServices