import React, { useEffect } from "react"
import CareersBk from "../assets/imgs/Careers/CareersBk.jpg"
import Training from "../assets/imgs/Careers/Training.png"
import Careers1 from "../assets/imgs/Careers/Careers1.JPG"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt, faGem } from "@fortawesome/free-regular-svg-icons"
import { faFile } from "@fortawesome/free-regular-svg-icons"
import { faSeedling } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const Careers = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    return (
        <>
            <main>
                <section id="breadcrumbs" className="d-flex align-items-center" style={{backgroundImage: `linear-gradient(rgba(12, 13, 14, 0.658), rgba(12, 13, 14, 0.644)),url(${CareersBk})`}}>
                    <div className="container position-relative" data-aos="fade-up" data-aos-delay="500">
                        <h1 className="pt-3">Careers at Fintrek</h1>
                    </div>
                </section>

                <section id="service-about us" className="service-about">
                    <div className="container">
                        <div className="section-title">
                            <h2>Join Fintrek</h2>
                            <p className="pt-2">Fintrek Marketing is an institute to modify staff into professional bankers. We are recognised for our training
                                and quality of work that makes us award-winning direct sales agency in UAE. We recruit for variety of roles across
                                our teams. If you are looking for your next career move in banking related sector, then please take some time to
                                explore the opportunities here at Fintrek Marketing.</p>
                            <div className="content pt-4">
                                <Link to={"/vacancies"} className="btn-learn-more">
                                    Join our team
                                </Link>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="work-process p-0">
                    <div className="row content mt-0">
                        <h3>Your best work start here</h3>
                        <div className="col-md-5 order-lg-2" data-aos="fade-left">
                            <img src={Training} className="img-fluid" alt=""/>
                        </div>
                        <div className="col-md-7 pt-2 pt-lg-5 order-lg-1" data-aos="fade-right">

                            <p>
                                We put forward best seats to escalate on career and provide many opportunities to shine as a most talented banker among the industry. We recognize the young talent bankers in our esteem organization and retain to appraise.
                            </p>
                            <p>Fintrek influences, guides and directs young sales person to a supreme banker. We cultivate the skills to improve processes, optimize time and drive customer retention.</p>
                            <p>Fintrek mentors the young bankers by implementing an efficient way to accomplish their goals by collaborating with them. Our coaching include customer relationship management,
                                financial strategical consultation and so forth. We not only guide them on financial strategies, but also help them implementing long-term changes to strengthen their position.</p>

                        </div>
                    </div>
                </section>

                <section id="career-benefits" className="career-benefits" style={{background: `linear-gradient(rgba(12, 13, 14, 0.8), rgba(12, 13, 14, 0.8)),url(${Careers1})`}}>

                    <div className="container pt-0" data-aos="fade-up">
                        <div className="section-title">
                            <p style={{color: "#fff", fontSize: "25px", paddingBottom: "20px"}}>Benefits choosing Fintrek as your career</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box iconbox-orange">
                                    <div className="icon">

                                    <FontAwesomeIcon className="position-relative" fontSize={50} icon={faGem} />
                                        <i className="bi bi-gem"></i>
                                    </div>
                                    <h4>Health & Wellness </h4>
                                    <p>We provide all the health coverage you would expect from the company.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box iconbox-orange ">
                                    <div className="icon">
                                    <FontAwesomeIcon className="position-relative" fontSize={50} icon={faFileAlt} />
                                    <i className="fa-regular fa-file"></i>
                                      
                                    </div>
                                    <h4>Competitive Salary</h4>
                                    <p>We offer a competitive benefits package with incentives and among other benefits.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box iconbox-orange">
                                    <div className="icon">
                                    <FontAwesomeIcon className="position-relative" fontSize={50} icon={faSeedling} />
                                    <i className="fa-solid fa-seedling"></i>
                                    </div>
                                    <h4>Level Up & Work-life Balance</h4>
                                    <p>Provide you the necessary training before you enter into the field.</p>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>

                <section id="cta" className="cta" style={{background: "none"}}>
                    <div className="container">

                        <div className="text-center">
                            <h3 style={{color: "#0f2b52"}}>Do you want to join Fintrek?</h3>
                            <p style={{color:" #0f2b52", fontSize: "20px" }}className="col-lg-8 mx-auto">
                                Fintrek is a dynamic company with a young and enthusiastic team. We believe in living life to the fullest and we encourage our employees to do the same.

                            </p>
                            <Link to={"/vacancies"}>
                                Explore Available Vacancies
                            </Link>
                        </div>

                    </div>
                </section>
            </main>
        

        </>
    )
}

export default Careers