import React, { useEffect } from "react"
import GEMSimage1 from "../assets/imgs/GEMS/GEMSimage1.jpg"
import GEMSimage2 from "../assets/imgs/GEMS/GEMSimage2.jpg"
import GEMSimage3 from "../assets/imgs/GEMS/GEMSimage3.jpg"
import GEMSimage4 from "../assets/imgs/GEMS/GEMSimage4.jpg"
import GEMSimage5 from "../assets/imgs/GEMS/GEMSimage5.jpg"
import GEMSimage6 from "../assets/imgs/GEMS/GEMSimage6.jpg"
import GEMSimage7 from "../assets/imgs/GEMS/GEMSimage7.jpg"
import GEMSimage8 from "../assets/imgs/GEMS/GEMSimage8.jpg"
import GEMSimage9 from "../assets/imgs/GEMS/GEMSimage9.jpg"
import GEMSimage10 from "../assets/imgs/GEMS/GEMSimage10.jpg"
import GEMSimage11 from "../assets/imgs/GEMS/GEMSimage11.jpg"
import GEMSimage13 from "../assets/imgs/GEMS/GEMSimage13.jpg"
import GEMSimage14 from "../assets/imgs/GEMS/GEMSimage14.jpg"
import GEMSimage15 from "../assets/imgs/GEMS/GEMSimage15.jpg"
import news from "../assets/imgs/GEMS/news.jpg"
import front from "../assets/imgs/awards/front.png"
import awardceremony from "../assets/imgs/AwardCeremony/awardceremony.png"   
import teamactivities from "../assets/imgs/TEAMACTIVITIES/teamactivities.png"

import relatedImages1 from "../assets/imgs/newsUpdate/image1.jpg"
import relatedImages2 from "../assets/imgs/newsUpdate/image2.jpg"
import relatedImages3 from "../assets/imgs/newsUpdate/image3.jpg"

import { Lightbox } from "react-modal-image"
import Slider from "react-slick"
import { Link } from "react-router-dom"
import { useState } from "react"

const NewsUpdate = () => {
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const [imageModal, setImageModal] = useState(false)
    const [imgUrl, setImgUrl] = useState("")

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        autoplayspeed: 3000,
        slickPlay: true,
    }

    return (
        <>
        <section class="breadcrumbs-inner" style={{background: `linear-gradient(rgba(6, 35, 65, 0.836), rgba(6, 37, 68, 0.856)), url(${news}) center`}} data-aos="zoom-out">

            <div class="container">
                <h2 style={{textAlign: "center" }} data-aos="fade-right">News &nbsp;& Updates</h2>
            </div>
        </section>

        <section id="blog" className="blog container">
            <div class="section-title" data-aos="fade-up">
                <p>Fintrek Marketing is a financial and strategic marketing agency with a strong focus on B2B marketing. We have been participating and organizing many events. We have conducted many events and would like to show recent events as well as those we participated in.</p>
            </div>
            <div class="container" data-aos="fade-up">

                <div class="row">

                    <div class="col-lg-8 entries p-0">
                        <article class="entry" style={{ padding: "30px 15px 30px 30px" }}>
                            <h3 class="title mb-4">Latest event</h3>
                            <div class="card-flyer">
                                <div class="text-box">
                                    <div class="image-box">
                                    <Slider {...settings}>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage2} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage3} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage4} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage5} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage6} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage7} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage8} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage9} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage10} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage11} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage13} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage14} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="item">
                                                <img className="img-fluid" src={GEMSimage15} alt="" />
                                            </div>
                                        </div>
                                    </Slider>
                                    </div>
                                    <div class="text-container">
                                        <h2 class="entry-title">
                                            GEMS Awareness Day 2021 - Atlantis, The Palm
                                        </h2>
                                        <div class="entry-content">
                                            <p class="d-none d-md-block">
                                                GEMS Awareness Day is an annual event held every year to introduce new teachers to the GEMS Education community through partners such as Fintrek Marketing on behalf of Emirates NBD. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                    <div class="col-lg-4 entries" style={{ padding: "30px 30px 30px 15px" }}>
                        <article class="sidebar">
                            <h3 class="title d-flex pb-3">Other Events</h3>
                            <div class="sidebar-item recent-posts">
                                <div class="portfolio-item filter-card">
                                    <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(teamactivities); setImageModal(true); }}>
                                        <img src={teamactivities} class="img-fluid" alt="" />
                                        <div class="portfolio-info">
                                            <h4>Team Activities</h4>
                                            <Link to={"#"} style={{color:" #bebbbb"}}>
                                                View More
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="details" style={{padding:" 5px 5px 0 5px"}}>
                                        <p style={{textAlign:" right", fontSize: "15px", color: "#fff"}}>Posted: Dec 12, 2020</p>
                                    </div>
                                </div>
                                <div class="portfolio-item filter-card">
                                    <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(front); setImageModal(true); }} >
                                        <img src={front} class="img-fluid" alt="Responsive image" />
                                        <div class="portfolio-info">
                                            <h4 className="text-center">New Branch Opening</h4>
                                            <Link to={"#"} style={{color:" #bebbbb"}}>
                                                View More
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="details" style={{padding: "5px 5px 0 5px"}}>
                                        <p style={{textAlign:"right", fontSize: "15px", color:"#fff"}}>Posted: Dec 7, 2021</p>
                                    </div>
                                </div>
                                <div class="portfolio-item filter-card">
                                    <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(awardceremony); setImageModal(true); }}>
                                        <img src={awardceremony} class="img-fluid" alt="Responsive image" />
                                        <div class="portfolio-info">
                                            <h4>Award Ceremony</h4>
                                            <Link to={"#"} style={{color:" #bebbbb"}}>
                                                View More
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="details" style={{padding: "5px 5px 0 5px"}}>
                                        <p style={{textAlign: "right" ,fontSize: "15px", color:" #fff"}}>Posted: Nov 16, 2021</p>
                                    </div>
                                </div>

                                <div class="portfolio-item filter-card mb-0">
                                    <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(teamactivities); setImageModal(true); }}>
                                        <img src={teamactivities} class="img-fluid" alt="Fintrek sales staff activities" />
                                        <div class="portfolio-info">
                                            <h4>Team Activities</h4>
                                            <Link to={"#"} style={{color:" #bebbbb"}}>
                                                View More
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="details" style={{padding: "5px 5px 0 5px"}}>
                                        <p className="m-0" style={{textAlign:" right", fontSize:" 15px", color:"#fff"}}>Posted: Oct 24, 2021</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="blog p-0 container sidebar1">
            <div class="sidebar1">
                <h3 class="sidebar-title" style={{color: "#0f2b52"}}>Related Events</h3>
                <div class="sidebar-item recent-posts row">
                    <div class="portfolio-item filter-card col-lg-4">

                        <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(relatedImages1); setImageModal(true); }}>
                            <img src={relatedImages1} class="img-fluid" alt="" />
                            <div class="portfolio-info justify-content-center">
                                <h4>GEMS Awareness Day</h4>
                                {/* <a href="GAD.php" style={{color:" #bebbbb;"}}>View More</a> */}
                            </div>
                        </div>
                    </div>


                    <div class="portfolio-item filter-card col-lg-4">
                        <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(relatedImages2); setImageModal(true); }}>
                            <img src={relatedImages2} class="img-fluid" alt="" />
                            <div class="portfolio-info justify-content-center">
                                <h4>Sales Activities</h4>
                                {/* <a href="sales-activities.php" style={{color:" #bebbbb;"}}>View More</a> */}

                            </div>
                        </div>


                    </div>
                    <div class="portfolio-item filter-card col-lg-4">
                        <div class="portfolio-wrap toggle" onClick={() => { setImgUrl(relatedImages3); setImageModal(true); }}>
                            <img src={relatedImages3} class="img-fluid" alt="" />
                            <div class="portfolio-info justify-content-center">
                                <h4> Training Session</h4>
                                {/* <a href="employee-training.php" style={{color:" #bebbbb;"}}>View More</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {
            imageModal && 
                <Lightbox
                    medium={imgUrl}
                    alt=""
                    onClose={() => setImageModal(false)}
                />
        }
        </>
    )
}

export default NewsUpdate