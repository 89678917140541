import { isEmpty } from "../config/funcs"

export const homeValidation = (data) => {
    let error = {}
    if(isEmpty(data.name)) error.name = "REQUIRED"
    if(isEmpty(data.dob)) error.dob = "REQUIRED"
    if(isEmpty(data.gender)) error.gender = "REQUIRED"
    if(isEmpty(data.email)) error.email = "REQUIRED"
    if(isEmpty(data.phone)) error.phone = "REQUIRED"
    if(isEmpty(data.nationality)) error.nationality = "REQUIRED"
    if(isEmpty(data.emirates)) error.emirates = "REQUIRED"
    if(isEmpty(data.company)) error.company = "REQUIRED"
    if(isEmpty(data.salary)) error.salary = "REQUIRED"

    return error
}

export const contactValidation = (data) => {
    let error = {}
    if(isEmpty(data.name)) error.name = "REQUIRED"
    if(isEmpty(data.email)) error.email = "REQUIRED"
    if(isEmpty(data.phone)) error.phone = "REQUIRED"
    if(isEmpty(data.message)) error.message = "REQUIRED"

    return error
}

export const employeeValidation = (data) => {
    let error = {}

    if(isEmpty(data.fname)) error.fname = "REQUIRED"
    if(isEmpty(data.lname)) error.lname = "REQUIRED"
    if(isEmpty(data.gender)) error.gender = "REQUIRED"
    if(isEmpty(data.email)) error.email = "REQUIRED"
    if(isEmpty(data.contact)) error.contact = "REQUIRED"
    if(isEmpty(data.nationality)) error.nationality = "REQUIRED"
    if(isEmpty(data.experience)) error.experience = "REQUIRED"
    if(isEmpty(data.position)) error.position = "REQUIRED"
    if(isEmpty(data.salary)) error.salary = "REQUIRED"
    if(isEmpty(data.address)) error.address = "REQUIRED"
    if(isEmpty(data.resume)) error.resume = "REQUIRED"

    return error
}