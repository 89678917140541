import axios from "axios";

export const applyService = async (data) => {
  try {
    await axios.post('https://api.fintrekmarketing.com/apply-home', data)
    return {
      status: "success",
      message: "Operation Success!"
    }
  } catch (error) {
    return {
      status: "failed",
      error: "Failed due to error: " + error.message
    }
  }
}

export const applyContact = async (data) => {
  try {
    await axios.post('https://api.fintrekmarketing.com/apply-contact', data);
    return {
      status: "success",
      message: "Operation Success!"
    }
  } catch (error) {
    return {
      status: "failed",
      error: "Failed due to error: " + error.message
    }
  }
}

export const getVacancyList = async () => {
  try {
    let respData = await axios.get('https://api.fintrekmarketing.com/get-vacancies')
    return {
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data
    }
  } catch (error) {
    return {
      status: "failed",
      error: "Failed due to error: " + error.message
    }
  }
}

export const applyOffer = async (formData) => {
  try {
    console.log(formData)
    await axios.post('https://api.fintrekmarketing.com/apply-employee', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return {
      status: "success",
      message: "Operation Success!",
    }
  } catch (error) {
    return {
      status: "failed",
      error: "Failed due to error: " + error.message,
    }
  }
}