import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"

import React from "react"
import { Link } from "react-router-dom"

import { faFacebookF, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row mx-auto">
                        <div class="col-lg-3 pt-0 pt-lg-2 col-md-12 footer-contact">
                            <h3>Fintrek <span>  Marketing</span></h3>
                            <p>
                                Business Bay <br />
                                Dubai<br />
                                United Arab Emirates <br />
                                <div class="pt-0 pt-lg-4">
                                    <strong>Phone:</strong> +971 458 10200<br />
                                    <strong>Email:</strong> about@fintrekmarketing.com<br />
                                </div>
                            </p>
                        </div>
                
                        <div class="col-lg-2 col-md-8 col-sm-8 col-7 footer-links">
                            <h4>Useful Links</h4>
                            <ul className="pt-1">
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/"}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/about"}>
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/our-services"}>
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/careers"}>
                                        Careers
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/contact"}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                
                        <div class="col-lg-3 col-md-4 col-sm-4 col-5 footer-links">
                            <h4>Our Services</h4>
                            <ul className="pt-1">
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/corporate-financing"}>
                                        Direct Sales
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"#"} onClick={() => { window.open("tel:+97144358112", "_blank") }} >
                                        Consultation
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/corporate-financing"}>
                                        Telesales
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/debt-collection-recovery"}>
                                        Debt collection
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"https://fintrek.me"} target="_blank">
                                        Risk Advisory
                                    </Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon className="pe-2" color="#3ce5fc" fontSize={10} icon={faChevronRight} /> 
                                    <Link to={"/marketing-services"}>
                                        Marketing
                                    </Link>
                                </li>
                            </ul>
                        </div>
                
                        <div class="col-lg-4 footer-newsletter">                        
                            <div class="col text-center text-md-center"> 
                                <div class="footer-content"> 
                                    <iframe style={{borderRadius: "15px", width: "90%", height: "200px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5488660599553!2d55.262392014484114!3d25.18470643838862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67ef44b087e5%3A0x832256e8ebb6c274!2sFintrek%20Marketing!5e0!3m2!1sen!2sae!4v1635685040018!5m2!1sen!2sae"></iframe>
                                </div>
                            </div>
                            <br />
                            <div class="social-links text-center text-md-center pt-3 pt-md-0">
                                <Link to={"#"} className="ms-1" onClick={() => { window.open('https://www.facebook.com/Fintrek-Marketing-1490907261175117/', '_blank'); }}>
                                    <FontAwesomeIcon color="#3ce5fc" fontSize={18} icon={faFacebookF} />
                                </Link>
                                <Link to={"#"} className="ms-1" onClick={() => { window.open('https://www.instagram.com/fintrekmarketing/', '_blank'); }}>
                                    <FontAwesomeIcon color="#3ce5fc" fontSize={18} icon={faInstagram} />
                                </Link>
                                <Link to={"/careers"} target="_blank" className="ms-1">
                                    <FontAwesomeIcon color="#3ce5fc" fontSize={18} icon={faMagnifyingGlass} />
                                </Link>
                                <Link to={"#"} className="ms-1" onClick={() => { window.open('https://www.linkedin.com/company/fintrek-marketing/', '_blank'); }}>
                                    <FontAwesomeIcon color="#3ce5fc" fontSize={18} icon={faLinkedinIn} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright text-center" style={{background: "#09143c", padding: "18px"}}>
                2023 &copy; Copyright <strong><span>Fintrek Marketing</span></strong>. All Rights Reserved
            </div>
        </footer>
    )
}

export default Footer